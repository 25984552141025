.local-select-modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 12;
    display: grid;
    place-content: center;
    visibility: hidden;
    background-color: rgba(33, 33, 33, 0.872);
    padding: 1rem;

    & h3 {
        font-size: 2rem;
        color: rgb(134, 107, 0);
        text-transform: uppercase;
        font-family: Cormorant;
        margin-top: .3rem;
    }

    &.open{
        visibility: visible;
    }

    &__container{
        padding: 1rem 1.2rem;
        background-color: var(--bg-color);
        max-width: 350px;
        border-radius: 1rem;
    }
    &__row {
        margin-top: 1rem;
        display: flex;
        gap: .8rem;
        justify-content: flex-start;
        flex-direction: column;
    }

    &__card {
        padding: .5rem;
        border: 1px solid rgb(71, 71, 71);
        cursor: pointer;
        font-size: 1.2rem;
        border-radius: 1rem;
        font-weight: normal;
        display: flex;
        align-items: center;
        color:  rgb(134, 107, 0);
        font-family: Cormorant;
        text-transform: uppercase;

        &:hover {
            border-color: rgb(134, 107, 0);
        }
    }

    & .branding {
        width: 5rem;
        height: auto;
    }
}