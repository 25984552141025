.filterable-product-table{
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: .5rem var(--p2);
}
.text-result{
  font-size: 1.1rem;
  font-weight: 500;
  color: #4a4a4a;
}