body {
  margin: 0;
  font-family: Mulish, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*, *::before, *::after {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
}

input, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  transition: all 0.2s ease-in-out;
  outline: none;
  background: none;
  border: none;
  width: 100%;
}

@font-face {
  font-family: 'Mulish';
  src: url('../fonts/Mulish-Regular.ttf');
  font-weight: 400;
}
@font-face {
  font-family: 'Mulish';
  src: url('../fonts/Mulish-Bold.ttf');
  font-weight: 700;
}
@font-face {
  font-family: 'Cormorant';
  src: url('../fonts/Cormonant-Bold.ttf');
  font-weight: 700;
}

* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  scroll-behavior: smooth;
  & ::-webkit-scrollbar {
      display: none;
  }
}