.scroll-top-button {
  position: fixed;
  bottom: -60px;
  right: 20px;
  z-index: 9999;
  opacity: 0;
  transition: all .3s ease-in-out;
  &.show {
    opacity: 1;
    bottom: 20px;
  }

  & button {
    background: none;
    border: none;
    outline: none;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
  }
}
