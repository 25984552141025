.background-layout{
    position: relative;

    &__content{
        position: relative;
        z-index: 1;
    }
}
.background{
    position: fixed;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    left: 0;
    top: 0;
    overflow: hidden;
    transition: .3s;
    font-size: 5px;

    & .leaf-big{
        position: absolute;
        right: -40em;
        bottom: -2em;
        transition: .3s;
        width: 70em;
        opacity: 0.5;
    }
    
    & .leaf-small{
        position: absolute;
        left: -6em;
        top: -6.5em;	
        transition: .3s;
        width: 50em;
        opacity: 0.5;
    }

    @media (min-width: 800px){
       font-size: 7px;
    }
}