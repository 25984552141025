.search-input {
  background: #eee1c3;
  border: 1px solid #dcca87;
  box-shadow: 0px 1px 12px #ab9d6a;
  border-radius: 21px;
  color: #666666;
  display: flex;
  padding: 0 .6rem;

  &__rodeo-icon{
      width: 40px;
      height: auto;
  }

  & input::placeholder { 
    color: #A49E8E;
    opacity: 1;
    font-size: 18px;
    font-weight: 400;
  }
}
