.local-select{
    display: grid;
    justify-content: center;
    margin-bottom: .3rem;
    
    & select {
        background-color: rgb(41, 41, 41);
        color: var(--bg-color);
        border-radius: 20px;
        padding: 5px;
    }
}