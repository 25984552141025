.product-item{
    text-align: left;
    border-radius: .5rem;
    margin-bottom: 1rem;
    color: #5F5F5F;
    width: 100%;

    &__text{
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    &__left{
        width: 100%;
    }
    &__name{
        font-family: Cormonant;
        font-size: 1.2rem;
        font-weight: 700;
        letter-spacing: .05rem;

        @media (min-width: 768px){
            font-size: 1.3rem;
        }
    }
    &__price{
        font-size: .9rem;
        font-weight: 700;
        margin-left: 3px;
        white-space: nowrap;
    }
    &__description{
        margin-top: .2rem;
        font-size: .9rem;
        font-weight: 500;
        line-height: 1.2rem;
        letter-spacing: .02rem;
        white-space: pre-line;

        @media (min-width: 768px){
            font-size: 1rem;
        }
    }

}

.product-locals {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
    margin-top: 4px;
}

.product-local-tag {
    font-size: 10px;
    background-color: rgba(113, 95, 72, 0.137);
    color: #aa414d  ;
    padding: 3px 6px;
    border-radius: 8px;
}