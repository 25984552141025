.price-rate{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & p {
        color: rgb(155, 141, 117);
        font-weight: bold;
        font-size: 1rem;
    }

    & .rate-title {
        font-size: 1.1rem;
        font-weight: bold;
        color: #6d6d6d;
        margin-top: 3px;
    }

    & .rate-value {
        font-size: 1.2rem;
        font-weight: bold;
        color: #6c6c6c;
    }
}