.product-category{
    margin: 1.5rem 0 1rem 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    &__text{
        & h1{
            font-family: Cormonant;
            font-size: 1.5rem;
            margin: 0;
            color: #9C1726;
            padding: 0;
            text-transform: uppercase;
            letter-spacing: .2rem;
            transition: .3s;

            @media (min-width: 768px){
                font-size: 2rem;
            }
        }
    }

    &__image{
        width:  100%;
        max-width: 400px;
        margin-bottom: 10px;
        & img{
            width: 100%;
            height: auto;
        }
    }
}