.branding{
    padding: 1rem;
}
.branding__logo{
    width: 100%;
    max-width: 160px;
    margin: 0 auto;
    
    & img{
        width: 100%;
        height: auto;
    }
}
.branding__text{
    & p{
        margin-top: 8px;
        color: #6f6f6f;
        font-weight: 400;
        font-size: 1rem;
    }
}