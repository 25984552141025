.footer{
    color: gray;
    padding-bottom: 1rem;

    & .copy-right{
        margin-bottom: 5px;
    }
    & a{
        color: rgb(172, 119, 56);
    }
}