:root {
  // Colores
  --first-color: #000;
  --second-color: #fff;
  --alt-color: pink;
  --bg-color: #EEE1C3;

  //padding
  --p4: 4rem;
  --p2: 2rem;
  --s1: 1rem;
  --s2: 2rem;
}

.App {
  text-align: center;
  background-color: var(--bg-color);
  min-height: 100vh;
}
.App-link {
  color: #61dafb;
}

.wrapper {
  width: 100%;
  max-height: 1240px;
  padding: 2rem;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: var(--p2) var(--p2);
}

.section-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: var(--p4) var(--p2);
}

.small-column {
  width: 100%;
  max-width: 550px;
  margin: 0 auto;
}
.input {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  border-radius: 200px;
}

.mb1 {
  margin-bottom: var(--s1);
}
.mb2 {
  margin-bottom: var(--s2);
}

.cls-us, .cls-btn-us{
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
   user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
   outline: none;
}

.cls-btn, .cls-btn-us{
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  outline: none;
  display: inline-block;
}