.category-filter{
    text-align: center;
    position: relative;
    &__row{
        display: flex;
        overflow-x: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        scroll-behavior: smooth;
        & ::-webkit-scrollbar {
            display: none;
        }
    }
    &__item{
        padding: .8rem .3rem 1.2rem .3rem;
        margin-right: .6rem;
        color: #9A9A9D;
        font-size: 1.1rem;
        font-weight: 500;
        transition: .5s;
        white-space: nowrap;
        cursor: pointer;
        &.active{
            color: #613E01;
        }
    }
    &__indicator{
        display: block;
        height: .25rem;
        background-color: #613E01;
        width: 150px;
        border-radius: 200px;
        bottom: 0;
        position: absolute;
        left: 0;
        transition: .3s ease-out all;
    }
}